<script setup lang="ts">
import { useUserStore } from '~/store/useUserStore';
import type { VehicleResponse, VehicleType } from '~/types/vehicle';

interface Props {
  isLabel?: boolean
}
withDefaults(defineProps<Props>(), {
  isLabel: () => true
})

const { links } = useConfigStore()
const { addToast } = useAppToast()
const userStore = useUserStore();
const { resetVehicleInformations, setVehicleInformations, vehicleTypeList } = useVehicle()
const { isMultipleVehicleSelectOpen } = useDashboard()

const vin = ref("")

async function getTypeIdByVin() {
    try{
        const typeList = await $fetch<VehicleType[]>('/api/vehicleTree/vin', { 
            query: {
                vin: vin.value,
                countryCode: "fr",
                languageCode: "fr"
            }
        })
       
        if(typeList.length <= 0) {
            addToast("Aucun véhicule ne correspond à ce VIN", "error")
            return
        }

        if(typeList.length > 1) {
            vehicleTypeList.value = typeList
            isMultipleVehicleSelectOpen.value = true
            return
        }

        await selectVehicle(typeList[0].tcdTypeId)

    } catch(err: any) {
        console.error(err)
        addToast("Une erreur est survenue lors de la recherche par VIN", "error")
    }
}

const selectVehicle = async (kType: number) => {
    try {
        const getCarByPlateResponse = await $fetch<VehicleResponse>('/api/siv/getCarByKtype', { query: {
            userEmail: userStore.user.customerEmail,
            Ktype: kType
        }})

        if(getCarByPlateResponse.success !== "1") throw new Error(`[${getCarByPlateResponse.err_code}] ${getCarByPlateResponse.msg}`)
    
        const genericVehicle = getCarByPlateResponse.carGenericDatas.filter((vehicle) => vehicle.KType === kType)
    
        resetVehicleInformations(true)
        setVehicleInformations(kType, vin.value, undefined, undefined, genericVehicle[0])

        await navigateTo(links.catalog)
        
    } catch(err: any) {
        console.error(err)
        addToast("Une erreur est survenue lors de la récupération du véhicule.", 'error')
    }
}
</script>

<template>
    <section>
        <h2 v-if="isLabel" class="text-base">Par VIN</h2>
        <form @submit.prevent="getTypeIdByVin" class="flex gap-3">
            <ElementsInput 
                class="vin-input w-full"
                id="vin"
                v-model="vin" 
                placeholder="Saisir le VIN"
            />
            <ElementsButtonLinear 
                class="button-search-plate" 
                preIcon="fa-solid-magnifying-glass" 
                type="submit"
            />
        </form>
    </section>
</template>
<style scoped lang="scss">
.vin-input :deep(input){
    height: 42px;
}

.vin-input.inputError :deep(input) {
    border-color: var(--default-error-color);
}

.vin-input :deep(input::placeholder) {
    font-family: var(--fontFamily);
    color: var(--neutralColor3);
    font-size: .9rem;
    font-weight: 500;
}
</style>